<a id="csv-donwload"></a>

<p-panel
  header="検索"
  [toggleable]="true"
  [collapsed]="false">
  <div class="grid">
    <div class="col-6">
      <div class="p-inputgroup full-height">
        <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
        <textarea
          pInputTextarea
          [(ngModel)]="searchText"
          style="width: 100%"
          placeholder="JANでコジマの商品を登録する"></textarea>
      </div>
    </div>

    <div class="col-6">
      <p-button
        label="登録待ちに追加"
        styleClass="p-button-info"
        (click)="doRegister()"
        [disabled]="!canRegister()"></p-button>
      <i
        class="pi pi-info-circle"
        pTooltip="{{ queueItems.length }} 件待ち"
        tooltipPosition="right"></i>
      <div
        *ngIf="!canRegisterNewItems"
        style="color: red">
        ※ 一週間に登録可能な商品数の上限を超過しました。しばらく時間を空けて改めて登録してください。
      </div>
    </div>

    <div class="col-6">
      <div class="p-inputgroup full-height">
        <div class="flex align-items-center gap-1">
          <p-checkbox
            [(ngModel)]="showZeroStock"
            inputId="showZeroStock"
            [binary]="true"></p-checkbox>
          <label for="showZeroStock">在庫がない商品を表示する</label>
        </div>
      </div>
    </div>

    <div class="col-12">
      <label for="ms-groupedArea">エリアでの絞り込み</label>
      <div class="card justify-content-center p-label">
        <p-multiSelect
          [options]="groupedArea"
          [group]="true"
          [(ngModel)]="selectedArea"
          (onChange)="doChangeArea($event)"
          defaultLabel="表示したいエリアを選択してください"
          display="chip">
          <ng-template
            let-group
            pTemplate="group">
            <div class="flex align-items-center">
              <span>{{ group.label }}</span>
            </div>
          </ng-template>
        </p-multiSelect>
      </div>
    </div>
    <div class="col-12">
      <label for="ms-groupedPref">都道府県での絞り込み</label>
      <div class="card justify-content-center p-label">
        <p-multiSelect
          [options]="groupedPref"
          [group]="true"
          [(ngModel)]="selectedPref"
          (onChange)="doChangePref($event)"
          defaultLabel="表示したい都道府県を選択してください"
          display="chip">
          <ng-template
            let-group
            pTemplate="group">
            <div class="flex align-items-center">
              <span>{{ group.label }}</span>
            </div>
          </ng-template>
        </p-multiSelect>
      </div>
    </div>
    <div class="col-12">
      <label for="ms-groupedStores">店舗での絞り込み</label>
      <div class="card justify-content-center p-label">
        <p-multiSelect
          [options]="groupedStores"
          [group]="true"
          [(ngModel)]="selectedStores"
          (onChange)="doChangeStores($event)"
          defaultLabel="表示したい店舗を選択してください"
          display="chip">
          <ng-template
            let-group
            pTemplate="group">
            <div class="flex align-items-center">
              <span>{{ group.label }}</span>
            </div>
          </ng-template>
        </p-multiSelect>
      </div>
    </div>
    <div class="col-12">
      <p-button
        styleClass="p-button-info"
        label="検索"
        icon="pi pi-search"
        (onClick)="doSearch()"></p-button>
    </div>
  </div>
</p-panel>
<br />
<br />
<p-table
  #bicTable
  [value]="products"
  columnResizeMode="expand"
  responsiveLayout="stack"
  [tableStyle]="{ 'min-width': '50rem' }"
  class="table-header-sticky"
  sortMode="multiple"
  [paginator]="true"
  [rows]="300"
  [globalFilterFields]="['jan', 'name']">
  <ng-template pTemplate="caption">
    <div class="table-caption">
      <div class="grid">
        <div class="col-3">
          <div class="p-d-flex">
            <button
              type="button"
              pButton
              pRipple
              icon="pi pi-download"
              (click)="downloadCsv(bicTable)"
              class="p-mr-2"
              pTooltip="CSV"
              tooltipPosition="bottom"></button>
          </div>
        </div>
        <div class="col-5"></div>
        <div class="col-3">
          <span class="p-input-icon-left ml-auto input-full">
            <i class="pi pi-search"></i>
            <input
              pInputText
              class="input-full"
              type="text"
              (input)="bicTable.filterGlobal($event.target.value, 'contains')"
              placeholder="検索キーワード" />
          </span>
        </div>
        <div class="col-1">
          <button
            pButton
            label="Clear"
            class="p-button-outlined"
            icon="pi pi-filter-slash"
            (click)="bicTable.clear()"></button>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th>JAN</th>
      <th pSortableColumn="price">
        価格
        <p-sortIcon field="price"></p-sortIcon>
      </th>
      <th>商品名</th>
      <th>在庫状況</th>
      <th>登録者</th>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-kojimaProduct>
    <tr class="{{ getRowStyle(kojimaProduct) }}">
      <td>
        {{ kojimaProduct.jan }}
        <br />
        登録日: ({{ kojimaProduct.createdAt | date: 'yyyy/MM/dd' }})
        <br />
        更新日: ({{ kojimaProduct.lastCheckedAt | date: 'yyyy/MM/dd' }})
        <br />
        総在庫店舗数: {{ kojimaProduct.totalStockNum }}
      </td>
      <td>
        {{ kojimaProduct.price.toLocaleString() }}
        <div *ngIf="kojimaProduct.anotherPrice">
          ({{ kojimaProduct.anotherPrice && kojimaProduct.anotherPrice.toLocaleString() }})
        </div>
        <i
          class="pi pi-user-edit clickable"
          style="font-size: 1rem"
          (click)="openAnotherPrice(kojimaProduct)"></i>
      </td>
      <td>
        <a
          href="https://www.kojima.net/ec/prod_detail.html?prod={{ kojimaProduct.jan }}&sku={{ kojimaProduct.jan }}"
          target="_blank">
          {{ kojimaProduct.name }}
        </a>
        （
        <a
          target="_blank"
          href="https://www.kojima.net/ec/shop_stock.html?prod={{ kojimaProduct.jan }}&sku={{ kojimaProduct.jan }}">
          在庫を確認
        </a>
        ）
      </td>
      <td>
        <app-biccamera-stock [stocks]="kojimaProduct.filteredStocks"></app-biccamera-stock>
      </td>
      <td>
        <div>{{ kojimaProduct.userName }}</div>
        <div>
          <i
            class="pi pi-trash clickable"
            style="color: red"
            (click)="doDelete(kojimaProduct)"></i>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>

<p-dialog
  header="価格を登録"
  [(visible)]="showAnotherPriceModal"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [draggable]="false"
  [resizable]="false">
  <div class="grid">
    <div class="col-10">
      <input
        type="number"
        class="p-inputtext"
        style="width: 100%"
        pInputText
        [(ngModel)]="anotherPriceModel.price"
        placeholder="価格を入力" />
    </div>
    <div class="col-2">
      <p-button
        label="登録"
        [disabled]="!anotherPriceModel.price"
        (onClick)="doRegisterAnotherPrice()"></p-button>
    </div>
  </div>
</p-dialog>
