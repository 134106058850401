<p-toast />
<p-confirmPopup />

<div
  *ngFor="let stock of stocks"
  style="margin-bottom: 5px">
  <p-tag
    severity="success"
    value="{{ stock.areaName }}"></p-tag>
  <p-tag
    severity="primary"
    value="{{ getStoreName(stock.storeCode, stock.storeName) }}"></p-tag>
  <p-tag
    *ngIf="stock.price"
    severity="warning"
    value="¥{{ stock.price.toLocaleString() }}"></p-tag>
  <p-tag
    [style]="getStyle(stock.hasStock)"
    [class]="getClass(stock)"
    value="{{ stock.stockNum }}"></p-tag>
  <p-tag
    *ngIf="!stock.isPurchased"
    class="clickable"
    icon="pi pi-check"
    severity="danger"
    value="購入/展示"
    (click)="confirm($event, stock)"></p-tag>
  <p-tag
    *ngIf="stock.isPurchased"
    class="clickable"
    severity="warning"
    value="購入/展示:{{ stock.purchasedUserName }}({{ stock.purchasedAt | date: 'MMdd' }})"
    (click)="confirmCancel($event, stock)"></p-tag>
  <p-tag
    *ngIf="!stock.isNotFound"
    class="clickable"
    icon="pi pi-check"
    severity="danger"
    value="試無にする"
    (click)="confirmNotFound($event, stock)"></p-tag>
  <p-tag
    *ngIf="stock.isNotFound"
    class="clickable"
    severity="warning"
    value="試無:{{ stock.notFoundUserName }}({{ stock.notFoundAt | date: 'MMdd' }})"
    (click)="confirmFound($event, stock)"></p-tag>
  <i
    class="pi pi-user-edit clickable"
    style="font-size: 1rem; margin-left: 5px"
    (click)="openMemo(stock)"></i>
  <i
    *ngIf="!!stock.memo"
    class="pi pi-info-circle"
    style="margin-left: 5px"
    [pTooltip]="tooltipContent"></i>
  <ng-template #tooltipContent>
    <div>{{ stock.memoUserName }}({{ stock.memoAddedAt | date: 'MMdd' }})</div>
    <div>{{ stock.memo }}</div>
  </ng-template>
</div>

<p-dialog
  header="メモを登録"
  [(visible)]="showMemo"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [draggable]="false"
  [resizable]="false">
  <div class="grid">
    <div class="col-10">
      <textarea
        type="number"
        class="p-inputtext"
        style="width: 100%"
        pInputText
        [(ngModel)]="memoModel.memo"
        placeholder="メモを入力"></textarea>
    </div>
    <div class="col-2">
      <p-button
        label="登録"
        [disabled]="!memoModel.memo"
        (onClick)="doRegisterMemo()"></p-button>
    </div>
  </div>
</p-dialog>
