<a id="csv-donwload"></a>
<p-button
  label="戻る"
  class="nav-button"
  styleClass="p-button-secondary"
  icon="pi pi-arrow-left"
  iconPos="left"
  (click)="goBack()"></p-button>
<p-button
  label="新規取り込み対象の追加"
  styleClass="p-button-success"
  icon="pi pi-plus"
  iconPos="left"
  (click)="toggleRegisterCategoryModal()"></p-button>
<p-button
  label="廃盤商品の登録"
  styleClass="p-button-primary"
  icon="pi pi-plus"
  iconPos="left"
  (click)="toggleRegisterJansModal()"></p-button>

<p-panel
  header="検索"
  [toggleable]="true"
  [collapsed]="false">
  <form
    [formGroup]="form"
    #f="ngForm">
    <div class="grid">
      <div class="col-12 lg:col-12 xl:col-12">
        <div>
          <b>JAN検索</b>
        </div>
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
          <textarea
            placeholder="JAN or ASIN検索"
            rows="4"
            id="janOrAsin"
            formControlName="keyword"
            class="input-full"></textarea>
        </div>
      </div>
      <div class="col-12 lg:col-4 xl:col-4">
        <label for="ms-groupedStores">廃盤取り込み対象での絞り込み</label>
        <div class="card justify-content-center p-label">
          <p-multiSelect
            [options]="categories"
            formControlName="category"
            defaultLabel="絞り込み対象を選択してください"
            display="chip">
            <ng-template
              let-group
              pTemplate="group">
              <div class="flex align-items-center">
                <span>{{ group.label }}</span>
              </div>
            </ng-template>
          </p-multiSelect>
        </div>
      </div>
      <div class="col-12 lg:col-4 xl:col-4">
        <label for="ms-groupedStores">登録日時の絞り込み</label>
        <div class="card justify-content-center p-label">
          <p-calendar
            formControlName="fromDate"
            dateFormat="yy/mm/dd"
            placeholder="登録日from"></p-calendar>
        </div>
      </div>
      <div class="col-6 lg:col-2 xl:col-2">
        <div>
          <b>アマゾンカテゴリの絞り込み</b>
        </div>
        <p-dropdown
          [options]="amazonCategories"
          formControlName="amazonCategory"
          optionLabel="name"
          [showClear]="true"
          placeholder="カテゴリ"
          filter="true">
          <ng-template pTemplate="selectedItem">
            <div *ngIf="form.value.amazonCategory">
              <div>{{ form.value.amazonCategory.name }}</div>
            </div>
          </ng-template>
          <ng-template
            let-category
            pTemplate="item">
            <div>{{ category.name }}</div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="col-12">
        <p-button
          styleClass="p-button-primary"
          label="検索"
          icon="pi pi-search"
          (onClick)="doSearch()"></p-button>
      </div>
    </div>
  </form>
</p-panel>
<div class="table-header-sticky-outer">
  全{{ products.length.toLocaleString() }}件
  <p-table
    #table
    [value]="products"
    columnResizeMode="expand"
    responsiveLayout="stack"
    [tableStyle]="{ 'min-width': '50rem' }"
    class="table-header-sticky">
    <!-- 廃盤は悪用されないようにcsv出力はコメントアウト
    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <button
          type="button"
          pButton
          pRipple
          icon="pi pi-download"
          (click)="outputCsv()"
          class="p-mr-2"
          pTooltip="CSV"
          tooltipPosition="bottom"></button>
      </div>
    </ng-template>
    -->
    <ng-template pTemplate="header">
      <tr>
        <th width="6%">画像</th>
        <th width="10%">Keepaグラフ</th>
        <th
          width="6%"
          pSortableColumn="asin">
          ASIN
          <p-sortIcon field="asin"></p-sortIcon>
        </th>
        <th width="6%">JAN</th>
        <th>商品名</th>
        <th
          width="4%"
          pSortableColumn="amazonCategory">
          Amazonカテゴリ
          <p-sortIcon field="amazonCategory"></p-sortIcon>
        </th>
        <th
          width="4%"
          pSortableColumn="amazonPrice">
          Amazon価格
          <p-sortIcon field="amazonPrice"></p-sortIcon>
        </th>
        <th
          width="4%"
          pSortableColumn="salesDate">
          Amazon発売日
          <p-sortIcon field="salesDate"></p-sortIcon>
        </th>
        <th
          width="15%"
          pSortableColumn="category">
          廃盤取り込み対象
          <p-sortIcon field="category"></p-sortIcon>
        </th>
        <th
          width="8%"
          pSortableColumn="createdAt">
          登録日時
          <p-sortIcon field="createdAt"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template
      pTemplate="body"
      let-product>
      <tr>
        <td>
          <a
            href="https://www.amazon.co.jp/dp/{{ product.asin }}"
            target="_blank">
            <img
              src="{{ product.imageUrl }}"
              style="width: 50px" />
          </a>
        </td>
        <td>
          <a
            href="https://keepa.com/#!product/5-{{ product.asin }}"
            target="_blank">
            <img
              style="max-width: 100%"
              src="https://graph.keepa.com/pricehistory.png?asin={{
                product.asin
              }}&domain=co.jp&width=500&height=250&salesrank=1" />
          </a>
        </td>
        <td>{{ product.asin }}</td>
        <td>
          {{ product.jan }}
        </td>
        <td>
          {{ product.productName }}
        </td>
        <td>{{ product.amazonCategory }}</td>
        <td>{{ product.amazonPrice.toLocaleString() }}</td>
        <td>{{ product.salesDate | japanDate }}</td>
        <td>
          <div *ngFor="let c of product.categories">
            <p-tag
              severity="{{ getSeverity(c.id) }}"
              value="{{ c.name }}({{ c.createdAt | japanDate }})" />
          </div>
        </td>
        <td>
          {{ product.discontinuedAt | japanDate }}
          <div>
            <i
              class="pi pi-user-edit clickable"
              style="font-size: 1rem; margin-left: 5px"
              (click)="openMemoModal(product)"></i>
            <i
              *ngIf="!!product.memo"
              class="pi pi-info-circle"
              style="margin-left: 5px"
              tooltipPosition="left"
              pTooltip="{{ product.memo }}"></i>
          </div>
          <div *ngIf="canDelete()">
            <i
              class="pi pi-trash clickable"
              style="font-size: 1rem; margin-left: 5px; color: red"
              (click)="deleteProduct(product.id)"></i>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog
  header="新規取り込み対象の追加"
  [(visible)]="showRegisterCategoryModal"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [draggable]="false"
  [resizable]="false">
  <ng-template pTemplate>
    <div class="grid">
      <div class="col-12 lg:col-12 xl:col-12">
        <label for="registerCategoryName">登録する対象の名前</label>
      </div>
      <div class="col-12 lg:col-12 xl:col-12">
        <input
          style="width: 100%"
          pInputText
          id="registerCategoryName"
          [(ngModel)]="registerCategoryName" />
      </div>
      <div class="col-12 lg:col-12 xl:col-12">
        <button
          pButton
          type="button"
          label="設定する"
          class="p-button-sm"
          (click)="doRegisterCategory()"></button>
      </div>
    </div>
  </ng-template>
</p-dialog>

<p-dialog
  header="廃盤JANの追加"
  [(visible)]="showRegisterJansModal"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [draggable]="false"
  [resizable]="false">
  <ng-template pTemplate>
    <div class="grid">
      <div class="col-12 lg:col-4 xl:col-4">
        <label for="ms-groupedStores">廃盤取り込み対象</label>
        <div class="card justify-content-center p-label">
          <p-dropdown
            [options]="categories"
            optionLabel="name"
            [showClear]="true"
            placeholder="カテゴリ"
            [(ngModel)]="registerCategory">
            <ng-template pTemplate="selectedItem">
              <div *ngIf="registerCategory">
                <div>{{ registerCategory.label }}</div>
              </div>
            </ng-template>
            <ng-template
              let-category
              pTemplate="item">
              <div>{{ category.label }}</div>
            </ng-template>
          </p-dropdown>
        </div>
      </div>
      <div class="col-12 lg:col-12 xl:col-12">
        <label for="registerJans">廃盤のJANを入力してください（改行区切り）</label>
      </div>
      <div class="col-12 lg:col-12 xl:col-12">
        <textarea
          style="width: 100%"
          pInputTextarea
          rows="10"
          id="registerJans"
          [(ngModel)]="registerJans"></textarea>
      </div>
      <div class="col-12 lg:col-12 xl:col-12">
        <button
          pButton
          type="button"
          label="設定する"
          class="p-button-sm"
          (click)="doRegisterJans()"></button>
      </div>
    </div>
  </ng-template>
</p-dialog>

<p-dialog
  header="メモを登録"
  [(visible)]="showMemoModal"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [draggable]="false"
  [resizable]="false">
  <div class="grid">
    <div class="col-10">
      <textarea
        type="number"
        class="p-inputtext"
        style="width: 100%"
        pInputText
        [(ngModel)]="memo"
        placeholder="メモを入力"></textarea>
    </div>
    <div class="col-2">
      <p-button
        label="登録"
        [disabled]="!memo"
        (onClick)="doRegisterMemo()"></p-button>
    </div>
  </div>
</p-dialog>
