import { Component, ElementRef, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { BicProduct, BicStock } from 'src/app/models/biccamera-product';
import { MessageService } from 'src/app/services/message.service';
import { RoutingService } from 'src/app/services/routing.service';
import { SubtitleService } from 'src/app/services/subtitle.service';
import { UserService } from 'src/app/services/user.service';
import {
  BiccameraProductsGQL,
  DeleteBiccameraProductGQL,
  RegsiterQueueBiccameraGQL,
  UpdateUserBiccameraProductGQL,
} from 'src/gql/generated';

@Component({
  selector: 'app-biccamera',
  templateUrl: './biccamera.component.html',
  styleUrls: ['./biccamera.component.sass'],
})
export class BiccameraComponent implements OnInit {
  searchText: string;
  form: UntypedFormGroup;
  isLoading: boolean;
  products: Array<BicProduct> = [];
  showZeroStock = false;
  showAnotherPriceModal = false;
  anotherPriceModel = {
    bicProduct: null,
    price: null,
  };

  canRegisterNewItems = true;

  finishedBuildStores = false;
  selectedArea = [];
  groupedArea = [];
  selectedPref = [];
  groupedPref = [];
  selectedStores = [];
  groupedStores = [];

  queueItems = [];

  splitStr = '::';
  selectedAreaKey = 'savedSelectedAreaKey';
  selectedPrefKey = 'savedSelectedPrefKey';
  selectedStoreKey = 'savedSelectedStoreKey';

  constructor(
    private elementRef: ElementRef,
    private message: MessageService,
    private subtitleService: SubtitleService,
    private routingService: RoutingService,
    private userService: UserService,
    private createGql: RegsiterQueueBiccameraGQL,
    private getGql: BiccameraProductsGQL,
    private userBicUpdateGql: UpdateUserBiccameraProductGQL,
    private deleteBicProductGQL: DeleteBiccameraProductGQL,
  ) {
    this.isLoading = false;
    this.routingService.loginRequired();
    this.userService.biccameraRequired();
    this.subtitleService.setSubTitle('ビックカメラ');
  }

  async ngOnInit() {
    this.selectedArea = JSON.parse(localStorage.getItem(this.selectedAreaKey)) || [];
    this.selectedPref = JSON.parse(localStorage.getItem(this.selectedPrefKey)) || [];
    this.selectedStores = JSON.parse(localStorage.getItem(this.selectedStoreKey)) || [];
    await this.loadProducts();
  }

  async loadProducts() {
    const resp = await firstValueFrom(this.getGql.fetch());

    this.canRegisterNewItems = resp.data.assertBiccameraProducts.canRegisterNewItem;

    this.queueItems = resp.data.queueBiccameraProducts;
    this.products = [];
    resp.data.biccameraProducts.forEach((p) => {
      if (p.isDeleted) {
        return;
      }
      let hokkaido: BicStock[] = [];
      let kinki: BicStock[] = [];
      let tyugoku: BicStock[] = [];
      let kyuushu: BicStock[] = [];
      let kantou: BicStock[] = [];
      let tyubu: BicStock[] = [];
      let product: BicProduct = {
        id: p.id,
        itemId: p.biccameraItemId,
        jan: p.product.jan,
        price: p.price,
        name: p.productName,
        lastCheckedAt: p.lastCheckedAt,
        createdAt: p.createdAt,
        hasAnyStock: false,
        hokkaido,
        kinki,
        tyugoku,
        kyuushu,
        kantou,
        tyubu,
        all: [],
        totalStockNum: p.totalStockNum,
        userBicId: p.userBiccameraProduct.id,
        userName: p.userBiccameraProduct.user?.name,
        anotherPrice: p.userBiccameraProduct.anotherPrice,
      };

      p.stocks.forEach((s) => {
        if (s.hasStock) {
          product.hasAnyStock = true;
        }
        const stock: BicStock = {
          prefecture: s.prefecture,
          area: s.areaName,
          storeName: s.storeName,
          hasStock: s.hasStock,
          stockMark: s.stockMark,
          note: s.note,
        };
        if (s.areaName === '関東') {
          kantou.push(stock);
        }
        if (s.areaName === '中部') {
          tyubu.push(stock);
        }
        if (s.areaName === '中国') {
          tyugoku.push(stock);
        }
        if (s.areaName === '近畿') {
          kinki.push(stock);
        }
        if (s.areaName === '九州') {
          kyuushu.push(stock);
        }
        if (s.areaName === '北海道') {
          hokkaido.push(stock);
        }
      });

      const prefSort = (s1, s2) => (s1.prefecture > s2.prefecture ? 1 : -1);
      kantou.sort(prefSort);
      tyubu.sort(prefSort);
      tyugoku.sort(prefSort);
      kinki.sort(prefSort);
      kyuushu.sort(prefSort);
      hokkaido.sort(prefSort);

      product.all = [...kantou, ...tyubu, ...tyugoku, ...kinki, ...kyuushu, ...hokkaido];
      product.filteredStocks = this.doFilters(product.all);

      this.buildSelectOpts(kantou, tyubu, tyugoku, kinki, kyuushu, hokkaido);

      this.products.push(product);
    });
  }

  buildSelectOpts(
    kantou: BicStock[],
    tyubu: BicStock[],
    tyugoku: BicStock[],
    kinki: BicStock[],
    kyuushu: BicStock[],
    hokkaido: BicStock[],
  ) {
    if (this.finishedBuildStores) {
      return;
    }
    // 何かしらデータが存在する場合に利用する
    if (kantou.length === 0) {
      return;
    }

    // TODO: area と pref もbuildする
    this.buildArea();
    this.buildPref(kantou, tyubu, tyugoku, kinki, kyuushu, hokkaido);
    this.buildGroupedStores(kantou, tyubu, tyugoku, kinki, kyuushu, hokkaido);

    this.finishedBuildStores = true;
  }

  buildArea() {
    this.groupedArea = [
      {
        label: 'エリア',
        value: 'area',
        items: [
          {
            label: '関東',
            value: '関東',
          },
          {
            label: '中部',
            value: '中部',
          },
          {
            label: '中国',
            value: '中国',
          },
          {
            label: '近畿',
            value: '近畿',
          },
          {
            label: '九州',
            value: '九州',
          },
          {
            label: '北海道',
            value: '北海道',
          },
        ],
      },
    ];
  }

  buildPref(
    kantou: BicStock[],
    tyubu: BicStock[],
    tyugoku: BicStock[],
    kinki: BicStock[],
    kyuushu: BicStock[],
    hokkaido: BicStock[],
  ) {
    this.groupedPref = [
      {
        label: '関東',
        value: '関東',
        items: [
          ...new Set(
            kantou.map((s) => {
              return s.prefecture;
            }),
          ),
        ].map((p) => {
          return {
            label: p,
            value: p,
          };
        }),
      },
      {
        label: '中部',
        value: '中部',
        items: [
          ...new Set(
            tyubu.map((s) => {
              return s.prefecture;
            }),
          ),
        ].map((p) => {
          return {
            label: p,
            value: p,
          };
        }),
      },
      {
        label: '中国',
        value: '中国',
        items: [
          ...new Set(
            tyugoku.map((s) => {
              return s.prefecture;
            }),
          ),
        ].map((p) => {
          return {
            label: p,
            value: p,
          };
        }),
      },
      {
        label: '近畿',
        value: '近畿',
        items: [
          ...new Set(
            kinki.map((s) => {
              return s.prefecture;
            }),
          ),
        ].map((p) => {
          return {
            label: p,
            value: p,
          };
        }),
      },
      {
        label: '九州',
        value: '九州',
        items: [
          ...new Set(
            kyuushu.map((s) => {
              return s.prefecture;
            }),
          ),
        ].map((p) => {
          return {
            label: p,
            value: p,
          };
        }),
      },
      {
        label: '北海道',
        value: '北海道',
        items: [
          ...new Set(
            hokkaido.map((s) => {
              return s.prefecture;
            }),
          ),
        ].map((p) => {
          return {
            label: p,
            value: p,
          };
        }),
      },
    ];
  }

  buildGroupedStores(
    kantou: BicStock[],
    tyubu: BicStock[],
    tyugoku: BicStock[],
    kinki: BicStock[],
    kyuushu: BicStock[],
    hokkaido: BicStock[],
  ) {
    this.groupedStores = [
      {
        label: '関東',
        value: '関東',
        items: kantou.map((s) => {
          return {
            label: s.prefecture + ' ' + s.storeName,
            value: s.prefecture + this.splitStr + s.storeName,
          };
        }),
      },
      {
        label: '中部',
        value: '中部',
        items: tyubu.map((s) => {
          return {
            label: s.prefecture + ' ' + s.storeName,
            value: s.prefecture + this.splitStr + s.storeName,
          };
        }),
      },
      {
        label: '中国',
        value: '中国',
        items: tyugoku.map((s) => {
          return {
            label: s.prefecture + ' ' + s.storeName,
            value: s.prefecture + this.splitStr + s.storeName,
          };
        }),
      },
      {
        label: '近畿',
        value: '近畿',
        items: kinki.map((s) => {
          return {
            label: s.prefecture + ' ' + s.storeName,
            value: s.prefecture + this.splitStr + s.storeName,
          };
        }),
      },
      {
        label: '九州',
        value: '九州',
        items: kyuushu.map((s) => {
          return {
            label: s.prefecture + ' ' + s.storeName,
            value: s.prefecture + this.splitStr + s.storeName,
          };
        }),
      },
      {
        label: '北海道',
        value: '北海道',
        items: hokkaido.map((s) => {
          return {
            label: s.prefecture + ' ' + s.storeName,
            value: s.prefecture + this.splitStr + s.storeName,
          };
        }),
      },
    ];
  }

  canRegister(): boolean {
    return !!this.searchText && !this.isLoading && this.canRegisterNewItems;
  }

  getStatus(st: boolean): string {
    return st ? '◯' : '×';
  }

  getMyStocks(stocks: BicStock[]): string {
    return stocks
      .map((s) => {
        return s.storeName;
      })
      .join('<br/>');
  }

  getRowStyle(product: BicProduct) {
    if (!product.hasAnyStock) {
      if (this.showZeroStock) {
        return 'unpublic';
      }
      return 'hide-table-row';
    }
    const isZetoStock = product.filteredStocks.filter((s) => s.hasStock).length == 0;
    if (isZetoStock) {
      if (this.showZeroStock) {
        return 'unpublic';
      }
      return 'hide-table-row';
    }

    return '';
  }

  async doRegister() {
    if (!this.canRegister()) {
      // なぜかdisabledでも押せることがある
      return;
    }
    const searchText = this.searchText;
    if (!searchText) {
      this.message.setFlashMessageWithClear('warn', '入力してください', '');
      return;
    }
    const texts = searchText.split(/\n/, -1);

    let ngTexts = [];
    this.isLoading = true;
    texts.forEach(async (txt) => {
      const splitted = txt.split(/\//, -1);
      let id;
      if (splitted.length > 1) {
        id = splitted[splitted.length - 1];
        if (!id) {
          id = splitted[splitted.length - 2];
        }
      } else {
        id = txt;
      }
      if (isNaN(id)) {
        ngTexts.push(txt);
      } else {
        await firstValueFrom(this.createGql.mutate({ itemId: id }));
      }
    });

    await this.loadProducts();
    if (ngTexts.length > 0) {
      this.message.setFlashMessageWithClear('warn', '登録できなかった商品があります', ngTexts.join('\n'));
    } else {
      this.message.setFlashMessageWithClear('success', '登録しました', '');
    }
    this.searchText = '';
    this.isLoading = false;
  }

  doFilters(stocks: BicStock[]) {
    stocks = this.doFilterStocks(stocks);
    stocks = this.doFilterArea(stocks);
    stocks = this.doFilterPref(stocks);
    stocks = this.doFilterStores(stocks);
    return stocks;
  }

  private doFilterStocks(stocks: BicStock[]) {
    if (stocks.length === 0) {
      return stocks;
    }
    // filter pref and store nmaes
    return stocks.filter((s) => s.hasStock);
  }

  private doFilterArea(stocks: BicStock[]) {
    if (this.selectedArea.length === 0 || stocks.length === 0) {
      return stocks;
    }
    // filter pref and store nmaes
    return stocks.filter((s) => {
      return this.selectedArea.includes(s.area);
    });
  }

  private doFilterPref(stocks: BicStock[]) {
    if (this.selectedPref.length === 0 || stocks.length === 0) {
      return stocks;
    }
    // filter pref and store nmaes
    return stocks.filter((s) => {
      return this.selectedPref.includes(s.prefecture);
    });
  }

  private doFilterStores(stocks: BicStock[]) {
    if (this.selectedStores.length === 0 || stocks.length === 0) {
      return stocks;
    }
    // filter pref and store nmaes
    return stocks.filter((s) => {
      return this.selectedStores.includes(s.prefecture + this.splitStr + s.storeName);
    });
  }

  doChangeArea($e) {
    localStorage.setItem(this.selectedAreaKey, JSON.stringify(this.selectedArea));
    this.products.forEach((p) => {
      p.filteredStocks = this.doFilters(p.all);
    });
  }

  doChangePref($e) {
    localStorage.setItem(this.selectedPrefKey, JSON.stringify(this.selectedPref));
    this.products.forEach((p) => {
      p.filteredStocks = this.doFilters(p.all);
    });
  }

  doChangeStores($e) {
    localStorage.setItem(this.selectedStoreKey, JSON.stringify(this.selectedStores));
    this.products.forEach((p) => {
      p.filteredStocks = this.doFilters(p.all);
    });
  }

  downloadCsv(table: any) {
    const products: BicProduct[] = table.filteredValue ?? this.products;
    const values = products.filter((p) => p.filteredStocks.length > 0);
    const csv = values.map((p) => p.jan + ',' + p.price + ',,,' + (p.anotherPrice ?? '')).join('\n');
    this.outputCsv(csv, 'biccamera.csv');
  }

  openAnotherPrice(bicProduct: BicProduct) {
    this.anotherPriceModel.bicProduct = bicProduct;
    this.showAnotherPriceModal = true;
  }

  async doRegisterAnotherPrice() {
    const id = this.anotherPriceModel.bicProduct.userBicId;
    const anotherPrice = this.anotherPriceModel.price;
    const result = await firstValueFrom(this.userBicUpdateGql.mutate({ id, anotherPrice }));

    if (result.data.updateUserBiccameraProduct) {
      this.anotherPriceModel.bicProduct.anotherPrice = anotherPrice;
    }

    this.showAnotherPriceModal = false;
    this.anotherPriceModel = {
      bicProduct: null,
      price: null,
    };
  }

  async doDelete(product: BicProduct) {
    const result = await this.deleteBicProductGQL.mutate({ id: product.userBicId }).toPromise();
    if (result.data?.deleteBiccameraProduct) {
      this.message.setFlashMessageWithClear('success', '削除成功', '削除しました', 3000);
      await this.loadProducts();
    } else {
      this.message.setFlashMessageWithClear(
        'error',
        'エラー',
        '予期せぬエラーが発生しました。もう一度やり直してください。',
        3000,
      );
    }
  }

  private outputCsv(csv: string, filename: string) {
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const elem = this.elementRef.nativeElement;
    const link = elem.querySelector('#csv-donwload') as HTMLAnchorElement;
    link.href = url;
    link.download = filename;
    link.click();
  }
}
